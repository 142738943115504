import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import { Divider, IconButton, ListItemText, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';
import StanderdConstants from '../../services/StanderdConstants';
import { InputWithDropDown } from '../../ButtonsIconsInputs/CustomInputs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ShowSkills({ onSkillsChange, existingData = [] }) {
    const [skills, setSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([{ id: '', name: '', weight: 50 }]);
    // Set initial data based on existingData prop
  console.log(selectedSkills)
    useEffect(() => {
        if (existingData && skills) {
            // Create a map for quick lookup of existingData by skillId
            const existingSkillsMap = new Map(existingData.map(item => [item.id, item.weight]));
    
            // Filter and transform skills in one pass
            const transformedSkills = skills
                .filter(skill => existingSkillsMap.has(skill.skillId)) // Ensure the skill exists in existingData
                .map(skill => ({
                    id: skill.skillId,
                    name: skill.skillName, // skillName is directly available
                    weight: existingSkillsMap.get(skill.skillId), // Get weight from map
                }));
    
            // Update selectedSkills state
            setSelectedSkills(transformedSkills);
        }
    }, [skills]); // Include 'skills' in the dependency array
    
    // Fetch available skills from API
    useEffect(() => {
        const fetchSkills = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/skills`, {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ UserId: StanderdConstants.UserId })
                });
                const data = await response.json();
                setSkills(data.data);
            } catch (error) {
                console.error('Failed to fetch skills:', error);
            }
        };

        fetchSkills();
    }, []);

    // Update the selected skills
    const handleSkillChange = (index, value) => {
        
        const updatedSkills = [...selectedSkills];
        updatedSkills[index].id = skills.find(skill => skill.skillName === value.skillName)?.skillId || '';
        updatedSkills[index].name = value.skillName;
        setSelectedSkills(updatedSkills);
        onSkillsChange(updatedSkills); // Notify parent component
    };

    const handleWeightChange = (index, value) => {
        const updatedSkills = [...selectedSkills];
        updatedSkills[index].weight = value;
        setSelectedSkills(updatedSkills);
        onSkillsChange(updatedSkills); // Notify parent component
    };

    const addSkillRow = () => {
        setSelectedSkills([...selectedSkills, { id: '', name: '', weight: 50 }]);
    };

    const removeSkillRow = (index) => {
        const updatedSkills = [...selectedSkills];
        updatedSkills.splice(index, 1);
        setSelectedSkills(updatedSkills);
        onSkillsChange(updatedSkills); // Notify parent component
    };

    // Get available skills by filtering out already selected ones
    const getAvailableSkills = (currentIndex) => {
        const selectedIds = selectedSkills.map(skill => skill.id);
        return skills.filter(skill => !selectedIds.includes(skill.skillId) || skill.skillName === selectedSkills[currentIndex].name);
    };

    return (
        <div>
            {selectedSkills.map((skill, index) => (
                <div key={index} className="mb-4">
                    <div className="flex justify-between  rounded-lg px-4 pt-2">
                        <FormControl sx={{ m: 1, width: 300 }}>
                        <InputWithDropDown
                className='w-4/6'
                data={getAvailableSkills(index)}
                dataField={'skillId'}
                dataText={'skillName'}
                inputId={'skilldropdown'}
                inputLabel={'skills'}
                inputName={'skillsdropdown'}
                onChange={(event, value) => handleSkillChange(index,value)}
                inputValue={skills.find(item=>item.skillName==skill.name)}

            />
                            {/* <TextField
                                label='Select Skills'
                                select
                                variant='standard'
                                labelId={`skill-checkbox-label-${index}`}
                                id={`skill-checkbox-${index}`}
                                value={skill.name || ''}
                                onChange={(e) => handleSkillChange(index, e)}
                                input={<OutlinedInput label="Select Skill" />}
                                MenuProps={MenuProps}
                            >
                                {getAvailableSkills(index).map((availableSkill) => (
                                    <MenuItem key={availableSkill.skillId} value={availableSkill.skillName}>
                                        <ListItemText primary={availableSkill.skillName} />
                                    </MenuItem>
                                ))}
                            </TextField> */}
                        </FormControl>

                        <div className="mx-3 w-2/3 mt-4">
                            {skill.weight}%
                            <Slider
                                value={skill.weight}
                                onChange={(e, value) => handleWeightChange(index, value)}
                                aria-labelledby="input-slider"
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                            />
                        </div>

                        <Divider orientation="vertical" variant="middle" flexItem />

                        <div className="flex items-center mt-2">
                            <IconButton onClick={() => removeSkillRow(index)} aria-label="delete">
                                <Delete />
                            </IconButton>
                        </div>
                    </div>
                </div>
            ))}

            <Button variant="outlined" className='w-full' onClick={addSkillRow}>
                Add Skill
            </Button>
        </div>
    );
}
