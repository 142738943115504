import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import { Divider, ListItemText, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import StanderdConstants from '../../services/StanderdConstants';
import { InputWithDropDown } from '../../ButtonsIconsInputs/CustomInputs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ShowCompetency({ onCompetenciesChange, existingData = [] }) {
    const [competencies, setCompetencies] = useState([]);
    const [selectedCompetencies, setSelectedCompetencies] = useState([]);

    // Fetch competencies on component mount
    useEffect(() => {
        const fetchCompetencies = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/competencies/competency`, {
                    method: 'post',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ UserId: StanderdConstants.UserId })
                });
                const data = await response.json();
                setCompetencies(data.data);
            } catch (error) {
                console.error('Failed to fetch competencies:', error);
            }
        };

        fetchCompetencies();
    }, []);

    // Initialize selected competencies from existing data
    useEffect(() => {
        if (existingData.length > 0 && competencies.length > 0) {
            const initialSelectedCompetencies = existingData.map(item => {
                const matchingCompetency = competencies.find(comp => comp.competencyId === item.competencyId);
                return {
                    competencyId: item.competencyId,
                    competencyName: matchingCompetency ? matchingCompetency.competencyName : '',
                    competencyWeight: item.competencyWeight
                };
            });
            setSelectedCompetencies(initialSelectedCompetencies);
        }
    }, [existingData, competencies]);

    const handleCompetencyChange = (index, value) => {
        
        const updatedCompetencies = [...selectedCompetencies];
        const selectedCompetency = competencies.find(comp => comp.competencyName === value);

        if (selectedCompetency) {
            updatedCompetencies[index] = {
                competencyId: selectedCompetency.competencyId,
                competencyName: selectedCompetency.competencyName,
                competencyWeight: updatedCompetencies[index].competencyWeight
            };
            setSelectedCompetencies(updatedCompetencies);
            onCompetenciesChange(updatedCompetencies);
        }
    };

    const handleWeightChange = (index, value) => {
        const updatedCompetencies = [...selectedCompetencies];
        updatedCompetencies[index].competencyWeight = value;
        setSelectedCompetencies(updatedCompetencies);
        onCompetenciesChange(updatedCompetencies);
    };

    const addCompetencyRow = () => {
        setSelectedCompetencies([...selectedCompetencies, { competencyId: '', competencyName: '', competencyWeight: 50 }]);
    };

    const removeCompetencyRow = (index) => {
        const updatedCompetencies = [...selectedCompetencies];
        updatedCompetencies.splice(index, 1);
        setSelectedCompetencies(updatedCompetencies);
        onCompetenciesChange(updatedCompetencies);
    };

    const getAvailableCompetencies = (currentIndex) => {
        const selectedNames = selectedCompetencies.map(comp => comp.competencyName);
        return competencies.filter(comp => !selectedNames.includes(comp.competencyName) || comp.competencyName === selectedCompetencies[currentIndex].competencyName);
    };

    return (
        <div>
            {selectedCompetencies.map((comp, index) => (
                <div key={index} className="mb-4 flex justify-between  rounded-lg px-4 pt-2 gap-1">
                    <FormControl sx={{ m: 1, width: 300 }}>

                <InputWithDropDown
                className='w-4/6'
                data={getAvailableCompetencies(index)}
                dataField={'competencyId'}
                dataText={'competencyName'}
                inputId={'comp_dropdown'}
                inputLabel={'Select Competency'}
                inputName={'skillsdropdown'}
                onChange={(event, value) => handleCompetencyChange(index,value)}
                inputValue={competencies.find(item=>item.competencyName==comp.competencyName)}

            />
                        {/* <TextField
                            variant='standard'
                            select
                            label='Select Competency'
                            labelId={`competency-checkbox-label-${index}`}
                            id={`competency-checkbox-${index}`}
                            value={comp.competencyName || ''}
                            onChange={(e) => handleCompetencyChange(index, e)}
                            input={<OutlinedInput label="Select Competency" />}
                            MenuProps={MenuProps}
                        >
                            {getAvailableCompetencies(index).map((competency) => (
                                <MenuItem key={competency.competencyId} value={competency.competencyName}>
                                    <ListItemText primary={competency.competencyName} />
                                </MenuItem>
                            ))}
                        </TextField> */}
                    </FormControl>
                    <div className="mx-2 w-2/3 mt-5">
                        {comp.competencyWeight}%
                        <Slider
                            value={comp.competencyWeight}
                            onChange={(e, value) => handleWeightChange(index, value)}
                            aria-labelledby="input-slider"
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                        />
                    </div>
                    <Divider orientation="vertical" variant="middle" mx-2 flexItem />

                    <div className="flex items-center mt-3">
                        <IconButton onClick={() => removeCompetencyRow(index)} aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </div>
            ))}
            <Button variant="outlined" className='w-full' onClick={addCompetencyRow}>Add Competency</Button>
        </div>
    );
}
