import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import StanderdConstants from '../../services/StanderdConstants';
import { InputWithDropDown } from '../../ButtonsIconsInputs/CustomInputs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ShowJobRole({ onJobRolesChange, existingData = [] }) {
    console.log(existingData)
    const [jobRoles, setJobRoles] = useState([]);
    const [selectedJobRoleIds, setSelectedJobRoleIds] = useState();
    console.log(existingData)
    console.log(selectedJobRoleIds)
    useEffect(()=>{ 
        console.log(jobRoles,existingData)
        if(jobRoles && existingData)
        {
            setSelectedJobRoleIds(existingData[0]?.id || null)
        }
        },[])
    useEffect(() => {
        const fetchJobRoles = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/jobroles`, {
                    method: 'post',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ UserId: StanderdConstants.UserId })
                });
                const data = await response.json();
                setJobRoles(data.data);
            } catch (error) {
                console.error('Failed to fetch job roles:', error);
            }
        };

        fetchJobRoles();
    }, []);

    const handleChange = (value) => {
        // console.log(selectedNames)
        // // const selectedNames = event.target.value;
        if(value)
        {

            setSelectedJobRoleIds(value?.jobroleId);
            onJobRolesChange(value?.jobroleId);
            console.log(value?.jobroleId);
        }
        
    };

    return (
        <div className="mb-4 flex justify-between  rounded-lg px-4 pt-2 gap-1">
            <FormControl variant='standard' className='w-full '>
           {jobRoles.length>0 && <InputWithDropDown
                className='w-4/6'
                data={jobRoles}
                dataField={'jobroleId'}
                dataText={'jobroleName'}
                inputId={'jobroledropdown'}
                inputLabel={'jobrole'}
                inputName={'jobroledropdown'}
                onChange={(event, value) => handleChange(value)}
                inputValue={jobRoles.find(item=>item?.jobroleId==selectedJobRoleIds)}
            />}
                {/* <TextField
                    select
                    variant='standard'
                    label='Select Job Roles'
                    labelId="job-role-checkbox-label"
                    id="job-role-checkbox"
                    value={jobRoles.filter(jobRole => selectedJobRoleIds.some(role => role.id === jobRole.jobroleId)).map(jobRole => jobRole.jobroleName)}
                    onChange={handleChange}
                    input={<OutlinedInput label="Select Job Roles" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {jobRoles.map((jobRole) => (
                        <MenuItem key={jobRole.jobroleId} value={jobRole.jobroleName}>
                            <ListItemText primary={jobRole.jobroleName} />
                        </MenuItem>
                    ))}
                </TextField> */}
            </FormControl>
        </div>
    );
}
