import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoCompletecompetency from './AutoCompletecompetency';
import { Button, IconButton, Slider, Snackbar } from '@mui/material';
import { ArrowBack, CloseOutlined, Save } from '@mui/icons-material';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import Heading from '../reusableComp/Heading';
import { InputWithDropDown } from '../../ButtonsIconsInputs/CustomInputs';
import usePermissions from '../reusableComp/PermissionCheck';
import { ArraySchema } from 'yup';

function Jobrolecompetency() {
    const permissionsNameForThisPage = {
        Add: 'JobroleCompetency-AddCompetency',
        Delete: 'JobroleCompetency-Delete',
        Save: 'JobroleCompetency-Save'

    }
    const navigate = useNavigate();
    const location = useLocation();
    const api_url = process.env.REACT_APP_API_URL;
    const data = location.state?.currentjobrole;
    const jobroleData = location.state?.jobrole;
    const [currentjobrole, setcurrentjobrole] = useState(data);
    const [Oldcompetencies, setOldCompetencies] = useState([]);
    const [Newselectedcompetency, setNewSelectedcompetency] = useState([]);
    const [sameWeight, setSameWeight] = useState(false);
    const [autoAdjust, setAutoAdjust] = useState(true);
    const [currentSelectedCompetencies, setcurrentSelectedCompetencies] = useState([])
    const [message, setmessage] = useState('')
    const { hasPermission }=usePermissions();


    useEffect(() => {
        // Fallback to empty arrays if values are not available
        const validNewCompetencies = Array.isArray(Newselectedcompetency) ? Newselectedcompetency : [];
        const validOldCompetencies = Array.isArray(Oldcompetencies) ? Oldcompetencies : [];
    
        // Combine the arrays and set the state
        setcurrentSelectedCompetencies([...validNewCompetencies, ...validOldCompetencies]);
    }, [Newselectedcompetency, Oldcompetencies]);
    


    const getCompetenciesOfaJobrole = async (jobrole) => {
        try {
            const response = await fetch(`${api_url}/alreadyManagedJobroleCompetency/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'jobroleId': `${jobrole.jobroleId}` })
            });

            if (response.ok) {
                const fetchedCompetencies = await response.json();
                setOldCompetencies(fetchedCompetencies);
            } else {
                console.log('Error occurred while fetching competencies');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    useEffect(() => {
        getCompetenciesOfaJobrole(currentjobrole);
    }, [currentjobrole]);

    const handleAddcompetency = (competency) => {

        if (!competency) {
            return setmessage('please select a competency first')

        }
        if (currentSelectedCompetencies.length >= 8) {
            alert("Maximum of 8 competencies allowed.");
            return;
        }

        if (competency && !currentSelectedCompetencies.some(s => s.competencyId === competency.competencyId)) {
            const newCompetency = { ...competency, jobroleId: currentjobrole, competencyWeight: 10 }; // Set default weight for new competency
            const allCompetencies = [...Oldcompetencies, ...Newselectedcompetency, newCompetency];
            const totalWeight = allCompetencies.reduce((sum, comp) => sum + comp.competencyWeight, 0);

            if (totalWeight > 100) {
                const scalingFactor = 100 / totalWeight;
                const scaledCompetencies = allCompetencies.map(comp => ({
                    ...comp,
                    competencyWeight: Math.max(1, Math.round(comp.competencyWeight * scalingFactor))
                }));
                setOldCompetencies(scaledCompetencies.filter(comp => Oldcompetencies.some(oldComp => oldComp.competencyId === comp.competencyId)));

                setNewSelectedcompetency(scaledCompetencies.filter(comp => !Oldcompetencies.some(oldComp => oldComp.competencyId === comp.competencyId)));

            } else {
                setNewSelectedcompetency([...Newselectedcompetency, newCompetency]);
            }
        }
        else {
            setmessage("competency already exist")
        }
    };
    const adjustWeights = (index, newWeight,) => {
        const totalOtherWeights = currentSelectedCompetencies.reduce((sum, comp, i) => sum + (i !== index ? comp.competencyWeight : 0), 0);
        const remainingWeight = 100 - newWeight;
        const adjustedWeights = currentSelectedCompetencies.map((comp, i) => {
            if (i === index) {
                return { ...comp, competencyWeight: newWeight };
            }
            return { ...comp, competencyWeight: Math.max(1, Math.round(comp.competencyWeight * (remainingWeight / totalOtherWeights))) };
        });

        setcurrentSelectedCompetencies(adjustedWeights)
    };

    const handleWeightChange = (index, newWeight) => {
        adjustWeights(index, newWeight);
    };

    const handleOldWeightChange = (index, newWeight) => {
        adjustWeights(index, newWeight);
    };

    const sendDatatoServer = async () => {
        try {
            const response = await fetch(`${api_url}/jobrole_competency`, {
                method: 'POST',
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify({ newC: Newselectedcompetency, oldC: Oldcompetencies })
            });
            if (response.ok) {
                console.log("Data saved successfully");
                getCompetenciesOfaJobrole(currentjobrole);
                setNewSelectedcompetency()
                setmessage("data saved")

            } else {
                console.log("Error occurred while saving data");
                setmessage("error occured")
            }
        } catch (error) {
            console.error("An error occurred while saving data:", error);
        }
    };

    const totalweight = currentSelectedCompetencies?.reduce((sum, competency) => sum + competency.competencyWeight, 0)

    useEffect(() => {
        setNewSelectedcompetency([]);
    }, [currentjobrole]);

    const handleChangejobrole = (jobroleId) => {
        setcurrentjobrole(jobroleId);
    };

    const handleEqualWeightDistribution = () => {
        const totalCompetencies = Oldcompetencies?.length || 0 + Newselectedcompetency?.length || 0;
        if (totalCompetencies > 0) {
            const equalWeight = Math.floor(100 / totalCompetencies);
            const remainingWeight = 100 - (equalWeight * totalCompetencies);

            setOldCompetencies(Oldcompetencies?.map(comp => ({ ...comp, competencyWeight: equalWeight })) || []);
            setNewSelectedcompetency(Newselectedcompetency?.map(comp => ({ ...comp, competencyWeight: equalWeight })) || []);

            // Add the remaining weight to the first competency to make the total exactly 100
            if (Oldcompetencies.length > 0) {
                setOldCompetencies(prev => {
                    const updated = [...prev];
                    updated[0].competencyWeight += remainingWeight;
                    return updated;
                });
            } else if (Newselectedcompetency.length > 0) {
                setNewSelectedcompetency(prev => {
                    const updated = [...prev];
                    updated[0].competencyWeight += remainingWeight;
                    return updated;
                });
            }
        }
    };

    useEffect(() => {
        if (sameWeight) {
            handleEqualWeightDistribution();
            setAutoAdjust(false)
        } else if (autoAdjust) {
            // Reset weights to handle auto-adjustment

            // setSameWeight(false)
        }
    }, [sameWeight, autoAdjust, Oldcompetencies, Newselectedcompetency]);


    const handleDelete = async (item) => {
        if (item.JobroleCompetencyId) {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/jobrole_competency/delete?id=${item.JobroleCompetencyId}`, {
                method: 'delete',
                headers: { 'Content-Type': "application/json" },

            })
            if (result.ok) {
                const updatedArray = Oldcompetencies.filter(i => i.JobroleCompetencyId !== item.JobroleCompetencyId);
                setOldCompetencies(updatedArray)

            }
        }
        else {
            const updatedArray = Newselectedcompetency.filter(i => i.skillId !== item.skillId);
            setNewSelectedcompetency(updatedArray)
        }
    }

    return (
        <div>
            <div className="col-span-full my-1 mx-2">

                <IconButton
                    onClick={() => navigate('/managejobrole')}
                    className="inline-flex border-none items-center px-4 py-2 bg-blue-500 text-white font-semibold text-sm rounded-md shadow hover:bg-blue-600 transition duration-200"
                >
                    <ArrowBack/>
                </IconButton>
            </div>
            <Heading heading={"Manage Competencies for Job Roles"} />

            <div className="flex flex-col md:flex-row justify-between mt-10 gap-6 border  md:p-6 mx-2 md:mx-2 rounded-lg shadow-md">

                <InputWithDropDown
                    className='w-1/2'
                    inputValue={currentjobrole}
                    onChange={(event, value) => handleChangejobrole(value)}
                    data={jobroleData}
                    inputName={'selectJobrole'}
                    inputLabel={'jobrole'}
                    inputId={'jobroledropdown'}
                    dataText={'jobroleName'}
                    dataField={'jobroleId'}


                />
                {hasPermission(permissionsNameForThisPage.Add) && < AutoCompletecompetency className='w-1/2' oncompetencyselect={handleAddcompetency} />
                }
            </div>

            <div className="overflow-x-auto m-2">
                <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                    <thead className="text-gray-50 bg-sky-600">
                        <tr>
                            <th className="py-3 px-6 text-left  font-semibold">Competency</th>
                            <th className="py-3 px-6 text-left  font-semibold">Adjust Weight</th>
                            {hasPermission('JobroleCompetency-Delete') && <th className="py-3 px-6   font-semibold text-center">action</th>
                            }

                        </tr>
                    </thead>
                    <tbody>

                        {currentSelectedCompetencies && currentSelectedCompetencies?.map((competency, index) => (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                                <td className="py-3 px-6 text-gray-700">{competency.competencyName}</td>
                                <td className="py-3 px-6 text-gray-700">
                                    <div>{competency.competencyWeight}%</div>
                                    <Slider
                                        value={competency.competencyWeight}
                                        onChange={(e, newValue) => handleOldWeightChange(index, newValue)}
                                        aria-label="Weight"
                                        valueLabelDisplay="auto"
                                    />
                                </td>
                                {hasPermission(permissionsNameForThisPage.Delete) && <td className="py-3 px-6 text-gray-700 flex justify-center">
                                    <DeleteConfirmation action={() => handleDelete(competency)} itemName={competency.competencyName} index={index} />
                                </td>}
                            </tr>
                        ))}

                    </tbody>
                    <tfoot className='bg-orange-600 text-gray-50'>
                        <tr>
                            <td className="py-3 px-6 ">Total weight</td>
                            <td className="py-3 px-6 ">{totalweight}%</td>
                            {hasPermission(permissionsNameForThisPage.Delete) && <td className="py-3 px-6 "></td>
                            }

                        </tr>
                    </tfoot>
                </table>
                <form className="mt-4 flex flex-row justify-start gap-4">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            checked={sameWeight}
                            onChange={() => setSameWeight(!sameWeight)}
                            className="mr-2"
                        />
                        <label className="text-sm md:text-base">All have same weight</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            checked={autoAdjust}
                            onChange={() => setAutoAdjust(!autoAdjust)}
                            className="mr-2"
                        />
                        <label className="text-sm md:text-base">Manually adjust</label>
                    </div>
                </form>

            </div>
            {message && <div className='fixed right-20 top-24 z-30  min:w-1/6 text-center p-2 -mt-1 bg-white border-2 flex justify-between  gap-5 items-baseline '>
                <div className=''>{message}</div><IconButton onClick={() => setmessage('')}><CloseOutlined /></IconButton>
            </div>
            }
            {hasPermission(permissionsNameForThisPage.Save) && hasPermission(permissionsNameForThisPage.Add) && <div className='flex justify-center  mt-4'>
                <Button variant='contained' sx={{ fontWeight: '600' }} className='font-bold flex gap-2  mx-2' onClick={sendDatatoServer}><Save fontSize='small' /> <span className='text-sm'>Save</span></Button>
            </div>}

        </div>
    );
}

export default Jobrolecompetency;
